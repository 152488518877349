import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import AOS from 'aos'
import 'aos/dist/aos.css'
import i18n from './i18n'
import store from "./state/store";
import copyText from "@meforma/vue-copy-to-clipboard";
import Notification from "./views/mainpages/components/Notification.vue"
import BootstrapVue3 from 'bootstrap-vue-3';
import Flutterwave from 'flutterwave-vue3'
import vClickOutside from "click-outside-vue3"
import VueApexCharts from "vue3-apexcharts";
import Maska from 'maska';
import TawkMessengerVue from '@tawk.to/tawk-messenger-vue-3';
import VueFeather from 'vue-feather';
import Particles from "particles.vue3";

import '@/assets/scss/config/default/app.scss';
import '@vueform/slider/themes/default.css';
import Axios  from 'axios';


AOS.init({
    easing: 'ease-out-back',
    duration: 1000
})



Axios.interceptors.request.use(
    
    config=> {
    const token = localStorage.getItem('accessToken');
    if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
        config.headers['Content-Type'] = 'application/json';
        config.headers[ 'Access-Control-Allow-Origin'] = '*';
    
    }
return config;
});

   Axios.interceptors.response.use(
    
    response=> {
return response;
}, error => {
    console.log("ssinss");
    if (error.response.status === 403) {
        store.commit('SetAuthLogout')
       
     router.push({ name: 'login'})
    } 
   throw error;
   });

createApp(App)
    .use(store)
    .use(router)
   .use(Flutterwave)
    .use(VueApexCharts)
    .component('Notification', Notification)
     .use(TawkMessengerVue, {
        propertyId : '5dbf8acd154bf74666b74d4f',
        widgetId : '1g5un96oj'
    })
    .use(copyText)
    .use(BootstrapVue3)
    .component(VueFeather.type, VueFeather)
    .use(Maska)
    .use(Particles)
    .use(i18n)
    .use(vClickOutside).mount('#app')


    