import store from "@/state/store";

export default [
         {
        path: "/user/dashboard",
        name: "dashboard",
        meta: {
          title: "Dashboard",
          authRequired: true,
        },
        component: () => import("../views/mainpages/Home.vue"),
      },

      {
        path: "/user/airtime",
        name: "airtime",
        meta: {
          title: "Airtime",
          authRequired: true,
        },
        component: () => import("../views/mainpages/Airtime.vue"),
      },
      {
        path: "/user/pricing",
        name: "pricing",
        meta: {
          title: "Service Pricing",
          authRequired: true,
        },
        component: () => import("../views/mainpages/Pricing.vue"),
      },

      {
        path: "/user/electricity",
        name: "electricity",
        meta: {
          title: "Electricity",
          authRequired: true,
        },
        component: () => import("../views/mainpages/Electricity.vue"),
      },


      {
        path: "/user/cabletv",
        name: "cabletv",
        meta: {
          title: "CableTv",
          authRequired: true,
        },
        component: () => import("../views/mainpages/Cabletv.vue"),
      },
      {
        path: "/user/data",
        name: "data",
        meta: {
          title: "Data",
          authRequired: true,
        },
        component: () => import("../views/mainpages/Data.vue"),
      },
      {
        path: "/user/education",
        name: "education",
        meta: {
          title: "Education",
          authRequired: true,
        },
        component: () => import("../views/mainpages/Education.vue"),
      },

      {
        path: "/user/betting",
        name: "betting",
        meta: {
          title: "Betting",
          authRequired: true,
        },
        component: () => import("../views/mainpages/Betting.vue"),
      },

      {
        path: "/user/showmax",
        name: "showmax",
        meta: {
          title: "Showmax",
          authRequired: true,
        },
        component: () => import("../views/mainpages/Showmax.vue"),
      },



      {
        path: "/user/referrals",
        name: "referrals",
        meta: {
          title: "My Referrals",
          authRequired: true,
        },
        component: () => import("../views/mainpages/Referrals.vue"),
      },

      {
        path: "/user/deposit",
        name: "deposit",
        meta: {
          title: "Quick Deposit",
          authRequired: true,
        },
        component: () => import("../views/mainpages/QuickDeposit.vue"),
      },
      {
        path: "/user/deposit/bank",
        name: "depositbank",
        meta: {
          title: "Bank Deposit",
          authRequired: true,
        },
        component: () => import("../views/mainpages/BankDeposit.vue"),
      },


      {
        path: "/user/security",
        name: "security",
        meta: {
          title: "User Security",
          authRequired: true,
        },
        component: () => import("../views/mainpages/Security.vue"),
      },

      {
        path: "/user/deposit/history",
        name: "deposithistory",
        meta: {
          title: "Deposit History",
          authRequired: true,
        },
        component: () => import("../views/mainpages/DepositHistory.vue"),
      },

      {
        path: "/user/transaction/:hash",
        props: true,
        name: "transaction",
        meta: {
          title: "Transaction",
          authRequired: true,
        },
        component: () => import("../views/mainpages/Transaction.vue"),
      },

      {
        path: "/user/transactions",
        props: true,
        name: "transactions",
        meta: {
          title: "Transactions",
          authRequired: true,
        },
        component: () => import("../views/mainpages/Transactions.vue"),
      },




      {
        path: "/user/security/pin",
        name: "pinset",
        meta: {
          title: "Security",
          
        },
        component: () => import("../views/mainpages/security/Security.vue"),
      },



      {
        path: "/verify/email",
        name: "verifyemail",
        meta: {
          title: "Verify Email",
       
        },
        component: () => import("../views/account/Pinverify.vue"),
      },



      {
        path: '/email/verify/:id/:token',
        name: 'emailverify',
        component: () => import('../views/account/approvelink.vue'),
        props: true,

        meta: {
          title: 'Verify Email',
          beforeResolve(routeTo, routeFrom, next) {
            // If the user is already logged in
            console.log(localStorage.getItem('authToken'));
            if (store.getters.isAuthenticated) {
              // Redirect to the home page instead
              next({ name: "default" });
            } else {
              // Continue to the login page
              next();
            }
          },
       
          
          
        },
      },
]