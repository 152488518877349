
// import router from "@/router";
import axios from 'axios';
export default {


 async login(context, payload, ) {

    
    let data = {message: null, status: false, object: false};
    
  await axios.post(process.env.VUE_APP_MAIN_URL+ "/auth/login", payload).catch((error) => {
      
       
   
        throw error;

}).then((response) => {



    if (response.data.status == true) {
        data.status = true;
        data.message = "Logged in successfully";

         
        context.dispatch('setbase', response.data.data)
        context.dispatch('settuserDetails', response.data.data.user)
       
    
       
    }




})


return data;


 },


 async setpin(context, payload, ) {

    
    let data = {message: null, status: false, object: false};
    
  await axios.post(process.env.VUE_APP_MAIN_URL+ "/auth/set/pin", payload).catch((error) => {
      
       
   
        throw error;

}).then((response) => {



    if (response.data.status == true) {
        data.status = true;
        data.message = "Transaction Pin Updated Successfully";

         
   
        context.dispatch('settuserDetails', response.data.data.user)
       
    
       
    }




})


return data;


 },





 async sendverificationlink(_) {

    console.log(_)

    let data = {message: null, status: false, object: false};
  await axios.get(process.env.VUE_APP_MAIN_URL+ "/auth/resend/email").catch((error) => {
      
        throw error;

}).then((response) => {



    if (response.data.status == true) {
        data.status = true;
        data.message = "Verification Link has been Sent Successfully";

      
    
       
    }
})


return data;


 },


 async verifyemailcode(context, payload) {



    let data = {message: null, status: false, object: false};
  await axios.post(process.env.VUE_APP_MAIN_URL+ "/auth/verify/email", {verify_code: payload.code}).catch((error) => {
      
        throw error;
}).then((response) => {



    if (response.data.status == true) {
        data.status = true;
        data.message = response.data.data.message;

      
    
          context.dispatch('settuserDetails', response.data.data.user)
       
    
    }
})


return data;


 },




 async verifyemail(context, payload) {



    let data = {message: null, status: false, object: false};
  await axios.get(process.env.VUE_APP_MAIN_URL+ "/auth/verify-email/" +payload.id+ "/" + payload.token, { params: { expires: payload.expires, signature: payload.signature }}).catch((error) => {
      
        throw error;
}).then((response) => {



    if (response.data.status == true) {
        data.status = true;
        data.message = response.data.data.message;

      
    
          context.dispatch('settuserDetails', response.data.data.user)
       
    
    }
})


return data;


 },



 async register(context, payload, ) {

    
    let data = {message: null, status: false, object: false};
    
  await axios.post(process.env.VUE_APP_MAIN_URL+ "/auth/register", payload).catch((error) => {
      
       throw error;

}).then((response) => {

console.log( response.data )

    // if (response.data.data.status == true) {
    //     data.status = true;
    //     data.message = "Logged in successfully";

         
        context.dispatch('setbase', response.data.data)
        context.dispatch('settuserDetails', response.data.data.user)
       
    
       
    // }




})


return data;





 },



//  async resetpassword(_, payload) {

    
//     let data = {}
    
//     try {
//         await useJwt.resetpassword(payload).then (response => {



//          this.data =response;
          
   
   
    
//         });
    
//     } catch (error) {
    
//         // data.status = false;
     

//         // if (error.response.status == 422) {
//         //     data.message = error.response.data.data;
//         //     data.object = true;
//         // }else {
         
//         //     data.message = error.response.data.data.message != undefined ? error.response.data.data.message : "Something Went Wrong Please Try again Later"


//         // }
   
//     }
// return data;
//  },



//  async changepassword(context, payload, _) {

    
//     let data = {message: null, status: false, object: false};
    
//     try {
//         await useJwt.changepassword(payload).then (response => {

//             if (response.data.status == true) {
//                 data.status = true;
//                 data.message = "Password Changed Sucessfully";

                 
//                 context.dispatch('userlogout')
                
               
            
               
//             }else {


//                 data.status = false;
//                 data.message = response.data.data.message;


//             }
        
          
   
   
    
//         });
    
//     } catch (error) {
       
//         data.status = false;
     

//         if (error.response.status == 422) {
//             data.message = error.response.data.data;
//             data.object = true;
//         }else {
         
//             data.message = error.response.data.data.message != undefined ? error.response.data.data.message : "Something Went Wrong Please Try again Later"


//         }
   
//     }
//     return data;
//  },

//  async changepin(_context_, payload, _) {

    
//     let data = {message: null, status: false, object: false};
    
//     try {
//         await useJwt.changepin(payload).then (response => {

//             if (response.data.status == true) {
//                 data.status = true;
//                 data.message = "Transaction Pin Changed Sucessfully";

                 
             
                
               
            
               
//             }else {


//                 data.status = false;
//                 data.message = response.data.data.message;


//             }
        
          
   
   
    
//         });
    
//     } catch (error) {
//         console.log(error.response.data)
//         data.status = false;
     

//         if (error.response.status == 422) {
//             data.message = error.response.data.data;
//             data.object = true;
//         }else {
         
//             data.message = error.response.data.data.message != undefined ? error.response.data.data.message : "Something Went Wrong Please Try again Later"


//         }
   
//     }
//     return data;
//  },


//  async sendverifycode(_) {

    
//     let data = {message: null, status: false, object: false};
    
//     try {
//         await useJwt.sendverifycode().then (response => {

//             if (response.data.status == true) {
//                 data.status = true;
//                 data.message =  response.data.data.message;
//             }else {
//                 data.status = false;
         
//                 data.message =  "Something Went Wrong Please Try again Later"
       
//             }
        
          
       
    
//         });
    
//     } catch (error) {
//         console.log(error)
     
//         data.status = false;
//         data.message =  "Something Went Wrong Please Try again Later"



   
//     }
//     return data;
//  },

//  async verifycode(context, payload, _) {

    
//     let data = {message: null, status: false, object: false};
    
//     try {
//         await useJwt.verifycode(payload).then (response => {

//             if (response.data.status == true) {
//                 data.status = true;
//                 data.message = response.data.data.message;
//                 data.object = false;
                 
//                 context.dispatch('setbase', response.data.data.user)
//                 context.dispatch('settuserDetails', response.data.data.user.user)
               
            
               
//             }else {

//                 data.object = false;
//                 data.status = false;
//                 data.message = response.data.data.message;

                 
               

//             }
        
          
   
   
    
//         });
    
//     } catch (error) {
//         console.log(error.response.data)
//         data.status = false;
     

//         if (error.response.status == 422) {
//             data.message = error.response.data.data;
//             data.object = true;
//         }else {
         
//             data.message = error.response.data.data.message != undefined ? error.response.data.data.message : "Something Went Wrong Please Try again Later"


//         }
   
//     }
//     return data;
//  },



//  async checkvavailability(context){

//    try {

//    await useJwt.me().then(response => {
// console.log(response)
  
              
//     if (response.status >= 200) {
//         if (response.data.data != null) {



//         localStorage.setItem('userData', JSON.stringify(response.data.data))
//         const token = localStorage.getItem('accessToken');
//         const userdata = localStorage.getItem('userData');

//         if (token && userdata) {
           
//             var user =   {
//                 refresh: token,
//                 token: userdata,
//                 user:response.data.data,
//             };
//             context.commit('setUser', user)
//             return true;
//         }
    
//     return false;



//         } else {
//             context.dispatch('userlogout')
//               router.push({name: 'login'});


//         }
//     }
   
//     console.log(response.data.data)

//         return false

//     })
//    } catch (error) {
 
//     if (error.response.status == 403) {

//         context.dispatch('userlogout')
//               router.push({name: 'login'});


    
        
//     }



//   return false; 
//    }    
 




//  },



async logout(){
    await axios.get(process.env.VUE_APP_MAIN_URL+ "/auth/logout").catch((error) => {
      
        throw error;

}).then((response) => {
console.log(response);


})

},

 async userlogout(context)   {
  


await context.dispatch('logout')
localStorage.removeItem('accessToken');
localStorage.removeItem('userData');
context.commit('SetAuthLogout');



return true;
  
 },

async loguserout(context) {

    await context.dispatch('userlogout')
    this.$router.push({name: 'login'});


}
,

//  async register(context, payload) {

    
//     let data = {message: null, status: false, object: false};
    
//     try {
//         await useJwt.register(payload).then (response => {
        
//             data.status = true;
//             data.message = "Registered in successfully";
//             context.dispatch('setbase', response.data.data)
//             context.dispatch('settuserDetails', response.data.data.user)
//         });
    
//     } catch (error) {
        
//         data.status = false;
     

//         if (error.response.status == 422) {
//             data.message = error.response.data.data;
//             data.object = true;
//         }else {
         
//             data.message = error.response.data.data.message != undefined ? error.response.data.data.message : "Something Went Wrong Please Try again Later"


//         }
   
//     }
//     return data;
//  }
// ,



settuserDetails(context, payload)  {

    const token = localStorage.getItem('accessToken');
    const user = localStorage.getItem('userdata');
    if (token && user) {
        context.commit('setUser', {
            token: token,
            refresh: token,
            user:  payload || user,
          
    } )  }
    },


async setbase(context, payload, ) {

 if(context.dispatch('isvalid', {token:  payload.access_token})) {

   
    localStorage.setItem("accessToken", payload.access_token)
    localStorage.setItem('userdata', JSON.stringify(payload.user))
 }

},


// hastoken(context){
//     const token = localStorage.getItem('accessToken');
//     const user = localStorage.getItem('userData');
   


// if (token && user) {

// return context.dispatch('isvalid', {token:  token}) && user
    
// }

// return false;



// },


// trylogin(context)  {

// return context.commit('hastoken');
// },

// setdatas()  {


//   if (context.commit('trylogin')) {
//        context.commit('setUser', {
//         token: localStorage.getItem('accessToken'),
//         refresh: context.commit('getfirsttoken' , {token: localStorage.getItem('accessToken')}),
//         user:  localStorage.getItem('userData'),
      
//     })

//     return true;
      
//   }

//   return false;
// }
// ,









 async isvalid(_, payloadd) {



    const payload = payloadd.token

    if (payload) {
   

        return payload.iss == process.env.VUE_APP_MAIN_URL+ "/auth/login" || process.env.VUE_APP_MAIN_URL+ "/auth/register"  ? true : false;

    }

    return false;
 }





//  getfirsttoken(_, payload) {

//  const part = payload.token.split('.')[1];
//  return this.decode(payload)



//  },


//  decodedata(_, payload)  {

//     return JSON.parse(Buffer.from(payload, 'base64'))


//  }




}