export default{
    getuser(state){
        return state.userdata;
    },
    token(state){
     return state.token;
    },

    isAuthenticated(state){
        return !!state.token;
    },

    loguserout(state){
        return state.userdata == null;
    },




    isVerifyEmail(state){
   
     if (state.userdata != undefined && state.userdata.verify_email == 0) {
         return  true;
     } else {
     
         return false;
         
     }




     
    },
    didAutoLogout(state){
      return state.didLogout;

    }
}