import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters'



export default {
    state() {
        return{
            userdata: {},
            token: null,
            load: false,
            refresh: null,
            tokenExpiration: null,
            didLogout: false,
            AutoLoginExecuted: false,
            LoggedIn: true,
        }
    },
    mutations,
    actions,
    getters,
  
}