export default {
    setUser(state, payload){
        state.userdata = payload.user;
        state.token = payload.token;
        state.refresh = payload.refresh;
        state.didLogout = false;
       
    },


    SetdidLogout(state){
        state.didLogout = true;
        state.userdata = null;
        state.token = null;
        state.refresh = null;
    }
,

    SetAuthLogin(state){
        state.AutoLoginExecuted = true;
    },

    SetAuthLogout(state){
        state.AutoLoginExecuted = false;
        state.didLogout = true;
        state.userdata = null;
        state.token = null;
        state.refresh = null;
    }

}