import store from "@/state/store";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Login",
      requiresUnAuth: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters.isAuthenticated) {
          // Redirect to the home page instead
          next({ name: "dashboard" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },


  {
    path: "/session/:hash/:code",
    name: "session",
    props: true,
    component: () => import("../views/account/session.vue"),
    meta: {
      title: "Session",
      requiresUnAuth: true,
      
    },
  },

  {
    path: "/register",
    name: "Register",
    component: () => import("../views/account/register.vue"),
    meta: {
      title: "Register",
      requiresUnAuth: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters.isAuthenticated) {
          // Redirect to the home page instead
          next({ name: "dashboard" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  
  {
    path: "/refer/:userr",
    name: "Refuser",
    props: true,
    component: () => import("../views/account/register.vue"),
    meta: {
      title: "Register",
      requiresUnAuth: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters.isAuthenticated) {
          // Redirect to the home page instead
          next({ name: "dashboard" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/reset-password/:email/:code",
    name: "resetpassword",
    props: true,
    component: () => import("../views/account/resetpassword.vue"),
    meta: {
      title: "Reset Password",
      requiresUnAuth: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters.isAuthenticated) {
          // Redirect to the home page instead
          next({ name: "dashboard" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/account/forgot-password.vue"),
    meta: {
      title: "Forgot Password",
      requiresUnAuth: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        localStorage.getItem('authToken')
        if (store.getters.isAuthenticated) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/",
    name: "default",
    meta: {
      title: "Home",
    
    },
    component: () => import("../views/landing/index.vue"),
  },
 
];